import { render, staticRenderFns } from "./QQForm.vue?vue&type=template&id=6c38e742&scoped=true&"
import script from "./QQForm.vue?vue&type=script&lang=js&"
export * from "./QQForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c38e742",
  null
  
)

export default component.exports